export default {
    countDays(start, end) {
        let start_date = start ? new Date(start) : new Date(),
            end_date = new Date(end),
            difference = end_date.getTime() - start_date.getTime(),
            days = Math.ceil(difference / (1000 * 3600 * 24));
        return days;
    },
    getFormattedDate() {
        var mm = new Date().getMonth() + 1;
        var yy = new Date().getFullYear();
        if (mm < 10) {
            mm = "0" + mm;
        }
        return yy + "-" + mm + "-" + "01";
    },

}
