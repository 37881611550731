<template>
  <div id="main">
    <router-view />
  </div>
  <router v-if="$route.name !== 'login'" />
  <loading v-if="isloading" />
</template>

<script>
import router from "./components/router/router.vue";
import loading from "./components/animation/loading.vue";
export default {
  name: "app",
  components: { router, loading },
  computed: {
    isloading() {
      return this.$store.getters.loading;
    },
  },
};
</script>

<style lang="scss">
@import url(./components/style/main.css);
</style>
