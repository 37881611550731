import { createStore } from "vuex";
let timeout = null;

export default createStore({
  state() {
    return {
      loading: false,
      page: 0,
      limit: 25,
      search: '',

    };
  },
  actions: {
    setloading(ctx, loading) {
      ctx.commit("setloading", loading);
    },
    setPage(ctx, page) {
      ctx.commit("setPage", page);
    },
    setLimit(ctx, limit) {
      ctx.commit("setLimit", limit);
    },
    setSearch(ctx, search) {
      ctx.commit("setSearch", search);
    },

  },
  mutations: {
    setloading(state, loading) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        state.loading = loading;
      }, 200);
    },
    setPage(state, page) {
      state.page = page;

    },
    setLimit(state, limit) {
      state.limit = limit;

    },
    setSearch(state, search) {
      state.search = search;
    },
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    page(state) {
      return state.page;
    },
    limit(state) {
      return state.limit;
    },
    search(state) {
      return state.search;
    },
  },

});
